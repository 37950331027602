import React from 'react';
import './static/Nav.css';
import logo from './images/logo.png';
//import Routes from './Routes';
import Landing from './containers/Landing';

function App() {
  return (
    <div className="App">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
          <img src={logo} height="30" width="30" className="navbar-brand" alt=""></img>

          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav ml-auto">
              
              <li className="nav-item">
                <a className="nav-link" href="#About">About Us</a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="#Services">Services</a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="#Strategy">Strategy</a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="#Whyus">Why Us</a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="#Contact">Contact Us</a>
              </li>

            </ul>
          </div>

        </nav>
      </div>

      <Landing></Landing>
    </div>
  );
}

export default App;
