import React from 'react';
import {render} from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom';
import {SnackbarProvider} from 'notistack';

const rootElement = document.getElementById("root");
render(<React.StrictMode>
        <BrowserRouter>
          <SnackbarProvider maxSnack={1}>
            <App />
          </SnackbarProvider>
        </BrowserRouter>
      </React.StrictMode>, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
