import React, { useState } from 'react';
import '../static/Landing.css';
import Background from '../images/background.jpg';
import Logo from '../images/logo.png';
import purpose from '../images/purpose.jpg';
import vision from '../images/vision.jpg';
import mission from '../images/mission.jpg';
import value from '../images/value.jpg';
import goals from '../images/goals.jpg';
import axios from 'axios';
// import { buildURL } from '../_helpers/url-builder';
import { useSnackbar } from 'notistack';

export default function Landing(props) {

    const { enqueueSnackbar } = useSnackbar();

    const [emailData, setEmailData] = useState({
        emailName: '',
        emailAddress: '',
        emailSubject: '',
        emailMessage: ''
    })
    const handleChange = (event) => {
        setEmailData({ ...emailData, [event.target.name]: event.target.value });
        event.preventDefault();
    }

    function handlePost(event) {
        axios.post('/api/sendemail', emailData)
            .then(res => {
                if (res.data.success) {
                    enqueueSnackbar('Message sent successfuly', {
                        variant: 'success'
                    });
                } else {
                    enqueueSnackbar('Message was not sent', {
                        variant: 'error'
                    });
                }
            })
            .catch(error => {
                enqueueSnackbar('Message was not sent', {
                    variant: 'error'
                });
            });
        event.preventDefault();
    }
    var emailRegex = /^[A-Za-z0-9!#$^&*|\-_+=~`?"]*([A-Za-z0-9!#$^&*|\-_+=~`?"]\.[A-Za-z0-9!#$^&*|\-_+=~`?"]+)*\.{0,1}[A-Za-z0-9!#$^&*|\-_+=~`?"]@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$$/;

    function isEmailValid(email) {
        var valid = emailRegex.test(email);
        if (!valid)
            return false;
        return true;
    }

    function handleEmail(event) {
        if (isEmailValid(emailData.emailAddress)) {
            handlePost();
        } else {
            enqueueSnackbar('Email is not valid, please enter it again', {
                variant: 'error'
            });
        }
        event.preventDefault();
    }

    return (
        <div className="landing">
            <div className="Header">
                <img className="backgroundImg" src={Background} alt="Background"></img>
                <img className="Logo" src={Logo} alt="Logo"></img>
                <h1 className="branding">M-JAY GRAND ENTERPRISES</h1>
                <p>
                    <a class="btn btn-info bvm-button" href="http://bvmgroup.org" role="button">BVM-GROUP</a>
                </p>
            </div>

            <div id="About" className="About">
                <h1>About Us</h1>
                <br />
                <h6>
                    M-Jay Grand Enterprises based in Hong Kong has been established by Vimal Chandwaney in
                    2013 as an export services company facilitating buying requirements for its international clients
                    worldwide. Our traditional business model is to provide most competitive lowest prices with
                    additional services such as identifying suitable products, negotiating best terms with reputed
                    manufacturers, pre-shipment inspection at the time of loading of containers and much more.
                    In a very short span of time, we have made our presence felt among our clients as most reliable
                    export services agency. <br />

                    <br />
                    An ISO 9001:2015 certified company
                </h6>
                <h6>
                    Proud owner of BVM Group
                </h6>
                <a class="btn btn-info" href="http://bvmgroup.org" role="button">BVM-GROUP</a>
            </div>

            <div id="Services" className="Services">
                <h1>General Business Activity</h1>
                <br />
                <h6>
                    Sourcing, Procurement, Inspections &amp; Exports of: <br />
                    Porcelainware <br />
                    Footwear <br />
                    Electronics <br />
                    Household Electronics. <br />
                    Mobile Phones <br />
                    Mobile Telecommunication <br />
                    Mobile Accessories <br />
                    Chemicals <br />
                    Sundries. <br />
                </h6>
            </div>

            <div id="Strategy" className="Strategy">
                <h1>Company Strategy</h1>
                <br />
                <div className="container-fluid">
                    <div className="row">
                        <div className="Purpose col-md-2">
                            <img src={purpose} alt=""></img>
                            <br />
                            <h2>Purpose</h2>
                            <br />
                            <br />
                            <h6>
                                To be a leader in the export
                                services sector by providing
                                enhanced services, relationship and
                                profitability for our clients.
                            </h6>
                        </div>
                        <div className="Vision col-md-2">
                            <img src={vision} alt=""></img>
                            <br />
                            <h2>Vision</h2>
                            <br />
                            <br />
                            <h6>
                                To provide quality services that
                                exceeds the expectations of our
                                esteemed customers.
                            </h6>
                        </div>
                        <div className="MissionStatement col-md-2">
                            <img src={mission} alt=""></img>
                            <br />
                            <h2>Mission Statement</h2>
                            <br />
                            <h6>
                                to build long term relationships
                                with our customers and clients
                                and provide exceptional
                                customer services.
                            </h6>
                        </div>
                        <div className="CoreValues col-md-2">
                            <img src={value} alt=""></img>
                            <br />
                            <h2>Core Values</h2>
                            <br />
                            <br />
                            <h6>
                                We believe in treating
                                our clients with respect and faith.
                                We integrate honesty, integrity
                                and business ethics into all
                                aspects in our business functioning.
                            </h6>
                        </div>
                        <div className="Goals col-md-2">
                            <img src={goals} alt=""></img>
                            <br />
                            <h2>Goals</h2>
                            <br />
                            <br />
                            <h6>
                                achieving excellence in the field
                                of export services management
                                and develop a strong base
                                of key customers.
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            <div id="Whyus" className="Whyus">
                <h1>Why Choose Us</h1>
                <br />
                <hr />
                <h6>True end-to-end export services with all documentation taken care of.</h6>
                <br />
                <hr />
                <h6>30 Years Experience in China, Singapore and Malaysia</h6>
                <br />
                <hr />
                <h6>Transparency</h6>
                <br />
                <hr />
                <h6>Sourcing as per client’s specific requirement &amp; swift order processing</h6>
                <br />
                <hr />
                <h6>Active contact with reputed manufacturers</h6>
                <br />
                <hr />
                <h6>Currently serving the African continent, Middle East and India</h6>
                <br />
            </div>

            <div id="Contact" className="Contact">
                <div className="container">
                    <h1>Contact Us</h1>
                    <form>
                        <div className="row">
                            <div className="form-group col-sm-6">
                                <label htmlFor="Name">Name</label>
                                <input type="text" className="form-control" id="Name" placeholder="Enter Name" value={emailData.emailName} name="emailName" onChange={handleChange}></input>
                            </div>
                            <div className="form-group col-sm-6">
                                <label htmlFor="Name">Email</label>
                                <input type="email" className="form-control" id="Email" placeholder="Enter Email" value={emailData.emailAddress} name="emailAddress" onChange={handleChange}></input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-sm-12">
                                <label htmlFor="Subject">Subject</label>
                                <input type="text" className="form-control" id="Subject" placeholder="Subject" value={emailData.emailSubject} name="emailSubject" onChange={handleChange}></input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-sm-12">
                                <label htmlFor="Message">Message</label>
                                <textarea className="form-control" id="Message" rows="6" value={emailData.emailMessage} name="emailMessage" onChange={handleChange}></textarea>
                            </div>
                        </div>
                        {/*TODO enable file upload later */}
                        {/* <div className="row">
                            <div className="form-group col-sm-12">
                                <label htmlFor="FileInput">File</label>
                                <input type="file" className="form-control-file" id="FileInput"></input>
                            </div>
                        </div> */}
                        <button type="submit" className="btn btn-primary" onClick={handleEmail}>Submit</button>
                    </form>
                </div>
            </div>
            <div id="Footer" className="Footer">
                <div className="row">
                    <div className="address col-sm-4">
                        <h6>
                            Address: <br />
                            Room 301 Kam On Building,
                            <br />
                            176A Queen's Road Central
                            <br />
                            Hong Kong
                        </h6>
                    </div>
                    <div className="email col-sm-4">
                        <h6>
                            Email:
                            vimal@mjaygrand.com <br />
                            export@mjaygrand.com <br />
                            vimalmjaygrand@gmail.com <br />
                        </h6>
                    </div>
                    <div className="contactNo col-sm-4">
                        <h6>
                            HK Mobile No./Whatsapp : +852- 9746 8144
                            Telephone : +852-2547 1140
                            China Mobile No. : +0086-15712110631
                        </h6>
                    </div>
                </div>
                <h6>Copyright &copy; M-Jay Grand 2020</h6>
            </div>
        </div>
    )
}